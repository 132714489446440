.main-card-image-price {
  width: 80%;
  height: 30%;
  display: flex;
  margin: 0 auto;
  margin-top: 30px;
  /* background: whitesmoke; */
  border-radius: 10px;
  box-shadow: 2px 2px 10px 2px #dadada;
}

.inmain-image {
  width: 24%;
  margin-top: 0.9vh;
  padding: 20px 20px;
}
.inmain-image img {
  border-radius: 8px;
}
.inmain-content {
  width: 50%;
  margin-top: 5px;
  margin-bottom: 5vh;
}
.inmain-content h2 {
  font-size: 28px;
  font-weight: 600;
}

.inmain-content .heading {
  padding-left: 30px;
}
.inmain-content p {
  border-top: 1px solid #ababab;
  margin-top: 8px;
  text-align: justify;
  margin-left: 30px;
  padding-top: 20px;
  font-size: 18px;
}
.mini-con {
  display: flex;
  object-fit: cover;
}
.mini-con img {
  margin-left: 30px;
  background-color: #dadada;
  border-radius: 100%;
  padding: 2px 2px;
  width: 30px;
}
.inmain-price {
  margin-left: 4rem;
  margin-top: 4rem;
  margin-bottom: 1rem;
}

.inmain-price-details {
  display: flex;
}
.duration-left {
  color: #ababab;
  height: 20%;
  margin-right: 5px;
}
.inmain-price-details h4 {
  font-size: 14px;
}
.inmain-price-details h3 {
  color: #000;
  font-weight: 600;
  font-size: 18px;
}
.duration-right {
  color: #ababab;
  margin-left: 5px;
  height: 20%;
}

.paise-duration {
  display: flex;
  margin-top: 5px;
}

/* .paise-duration img {
  width: 30px;
  height: 40px;
} */
.paise-duration h3 {
  font-size: 28px;
  margin-top: 2px;
}

.inmain-price button {
  color: #fff;
  background: #12384b;
  font-weight: 600;
  border-radius: 5px;
  margin-top: 50px;
  padding: 10px 10px;
  width: 100%;
}
.inmain-price button:hover {
  background-color: #f8a91a;
  font-weight: 700;
  color: #000;
}

@media screen and (max-width: 768px) {
  .main-card-image-price {
    width: 95%;
    height: 30%;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    margin-top: 30px;
    border-radius: 10px;
    box-shadow: 2px 2px 10px 2px #dadada;
  }
  .mini-con {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .mini-con img {
    margin-top: 5px;
  }
  .inmain-content {
    width: 80%;
    margin-top: 5px;
  }
  .inmain-content p {
    border-top: 1px solid #ababab;
    margin-top: 8px;
    padding-top: 20px;
    font-size: 18px;
    text-align: left;
  }
  .inmain-image {
    width: 90%;
    margin-top: 4.7vh;
    padding: 10px 10px;
  }
  .inmain-image img {
    border-radius: 8px;
  }
  .inmain-price {
    margin-left: 1.8rem;
    margin-right: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .paise-duration h3 {
    font-size: 28px;
    margin-top: 2px;
  }
}

@media screen and (max-width: 400px) {
  .main-card-image-price {
    width: 95%;
    height: 30%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 auto;
    margin-top: 30px;
    border-radius: 10px;
    box-shadow: 2px 2px 10px 2px #dadada;
  }
  .mini-con {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .mini-con img {
    margin-top: 5px;
  }
  .inmain-content {
    width: 90%;
    margin-top: 5px;
  }
  .inmain-image {
    width: 100%;
    /* padding: 20px 20px; */
  }
  .inmain-image img {
    border-radius: 8px;
  }
  .paise-duration h3 {
    font-size: 18px;
    margin-top: 2px;
  }
}

.content-container {
  display: flex;
  justify-content: space-between;

  max-width: 1200px;
  height: 100vh;
  margin: 0 auto;
  padding: 40px;
  padding-top: 10%;
}

.left-content {
  flex: 1;

}

.blue-heading {
  font-size: 2.7em;
  color: darkblue;

}
.left-para {
  font-size: 1.1em;
  text-align: justify;
}

.right-content {
  margin-top: 25px;
  flex: 1;
  text-align: center;
}

.cofounder-heading {
  font-size: 1.5em;
  margin-bottom: 10px;
}
.color-head {
  color: darkblue;
  font-weight: 600;
}

.img-cofounder {
  max-width: 80%;
  height: auto;
  border-radius: 18px;
  margin-top: 10px;
  margin-left: 100px;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .content-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .right-content {
    order: -1;

    margin-top: 0;
  }

  .img-cofounder {
    margin-left: 50px;
    /* Reset margin for smaller screens */
  }
  .blue-heading {
    width: 100%;
    font-size: 30px;
  }
}

/* Responsive styles for iPhone 6, 7, 8 (375x667) */
@media (max-width: 475px) {
  .blue-heading {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .left-para {
    font-size: 1em;

  }

  .cofounder-heading {
    font-size: 1.2em;
  }

  .img-cofounder {
    margin: 0 auto;
  }
}

/* @import url('https://fonts.googleapis.com/css2?family=Lemon&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap");

#abril-fatface-regular {
  font-family: "Abril Fatface", serif;
  font-weight: 400;
  font-style: normal;
}
.adventure-detail-card {
  /* margin: 1rem; */
  padding: 15px;
  border-radius: 8px;
  border: 1px solid rgb(224, 209, 209);
}

#outer-row {
  max-width: 100%;
  margin: 1rem;
  background-color: rgb(247, 242, 242);
}
#sec-img {
  margin-top: 1.5rem;
}
#sec1 {
  margin-right: 10px;
  height: 20rem;
}
.h4 {
  color: #999;
}
.line-3 hr {
  width: 95%;
  height: 1px;
  border: 0;
  background: black;
}
#explore {
  color: orange !important;
}
.sold-row {
  min-width: 95%;
  border: 1px solid rgb(204, 193, 193);
  /* height: fit-content; */
  /* border-radius: 10px;s */
  /* padding: 8px; */
  position: sticky;
  top: 10px;
}
.inner-left {
  width: 70%;
}
.inner-right {
  width: 30%;
}

.adventure-card-image {
  height: 100%;
  width: 100%;
}
.adventure-card-image:hover {
  filter: brightness(50%);
}
.experience-content ul li {
  margin-left: 16px;
  padding: 0;
}

/* @media only screen and (min-width: 768px) {
  
  
  .sold-row {
    width: 15rem;
  }
  
} */

#Duration {
  color: black;
  font-size: 1.7rem;
  font-weight: 500;
  text-decoration: underline;
}
.Duration{
  font-size: 25px;
}
.time-font{
  font-size: 20px;
}

.time {
  display: flex;
  flex-direction: column;
}
.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Optional, but recommended for maintaining aspect ratio */
}

/* ----------------------------------------------------->>>>>>>>>>>>>>>>Accordian css */
.accordion-section {
  border-bottom: 1px solid #ccc;
}
#DownUp {
  transition: all 3s ease-in-out;
}

.accordion-title {
  cursor: pointer;
  padding: 10px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ccc;
}

.accordion-title.active {
  background-color: #ddd;
  max-height: 2000px;
}

.accordion-content {
  padding: 10px;
  max-height: 10;
  overflow: hidden;
  transition: max-height 6s ease-out;
}
/* ------------------------Over .accordion-content------------ */
.thanks-review {
  margin-left: 20rem;
}
.review-text {
  width: 20rem;
}
.button-incr {
  background-color: #f5f5f5;
  color: black;
  margin: 1rem;
}
.button-incr:hover {
  background: white;
}

.adventure-detail-card {
  border: 0px solid white;
}
@media screen and (max-width:750px){
   .package-content{
    margin-left: 0px;
   }
   .Duration{
    font-size: 20px;
  }
  .time-font{
    font-size: 12px;
  }
  .icon{
    font-size: 20px;
    margin-left: 6px;
  }



  .main-package {
    flex-direction: column;
  }

  .inner-left,
  .inner-right {
    width: 100%;
  }

  .inner-right {
    order: 1; 
    margin: 2rem;
  }
  .city-name{
    font-size: 40px;
  }
  .caption{
    font-size: 28px;
  }
}
@media screen and (max-width: 480px) {
  
  .description-cont{
    margin-right: 3px;
  }
  .city-name{
    font-size: 30px;
  }
  .caption{
    font-size: 20px;
  }
  .line-3{
    width: 20rem;
  }
  .package-content{
    margin-left: 0px;
  }
  .Duration{
    font-size: 10px;
  }
  .time-font{
    font-size: 8px;
  }
  .icon{
    font-size: 15px;
    margin-left: 6px;
  }
  .display{
    display: none;
  }
  .flex{
    margin: 0px;
    padding: 0px;
  }
  .border-l{
    height: 40px;
    margin: 3px;
  }
  .time{
    margin: 0px;
    padding: 0;
  }
  /* .mx-3{
    margin-left:2px ;
    margin-right:2px ;
    padding: 0px;
  } */
 
  .main-package {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  

  
  .sold-row {
    max-width: 100%;
    border: 1px solid rgb(204, 193, 193);
    top: 10px;
    
  }

  .main-package {
    flex-direction: column;
  }

  .inner-left{
    width: 100%;
  }
  .inner-right {
    width: 80%;
    margin-left: 2px;
  }

  .inner-right {
    order: 1; /* Move .inner-right to bottom */
  }
  .Inc-Exl{
    width: 90%;
  }

  
}

@media screen and (max-width: 638px) {
  .display{
    display: none;
  }
}
@media screen and (max-width: 1200px) {
 .sold-row{
  margin-left: 30px;
  
 }
 .display{
  display: none;
 }
}
.imageCard-main {
  width: 90%;
  height: 250px;
  display: flex;
  overflow: hidden;
  margin: 30px auto;
}
.containing-image1-div {
  position: relative;
  text-align: center;
  width: 100%;
  justify-content: center;
  min-width: 18%;
}
.image1-div-img {
  padding: 0 10px;
}
.containing-image1-div img {
  width: 260px;
  height: 240px;
  border-radius: 10px;
  object-fit: cover;
  /* z-index: 0; */
}
.containing-image1-div h2 {
  position: absolute;
  bottom: 3%;
  font-size: 20px;
  margin-left: 16%;
  color: #fff;
  font-weight: 600;
  text-shadow: 1px 1px 1px #ffffff7f;
}

.containing-image1-div:hover {
  cursor: pointer;
}
.containing-image1-div img:hover {
  box-shadow: 2px 2px 2px 2px #00000052;
  filter: brightness(50%);
}

/* @media screen and (max-width: 1200px) {
  .imageCard-main {
    overflow-x: scroll;
    justify-content: space-between;
  }

  .containing-image1-div {
    justify-content: center;
    margin-right: 25vw;
    width: 120%;
  }
  .containing-image1-div h2 {
    margin: 0 8%;
  }
} */

/* @media screen and (max-width: 991px) {
  .imageCard-main {
    overflow-x: scroll;
    justify-content: space-between;
  }

  .containing-image1-div {
    justify-content: center;
    margin-right: 30vw;
    width: 120%;
  }
  .containing-image1-div h2 {
    margin: 0 8%;
  }
} */

@media screen and (max-width: 768px) {
  .imageCard-main {
    overflow-x: scroll;
    justify-content: space-between;
  }

  .containing-image1-div {
    /* display: flex; */
    justify-content: center;
    margin-right: 35vw;
    width: 140%;
  }
  .containing-image1-div h2 {
    margin: 0 8%;
  }
}
/* @media screen and (max-width: 600px) {
  .imageCard-main {
    overflow-x: scroll;
    justify-content: space-between;
  }

  .containing-image1-div {
    justify-content: center;
    margin-right: 64vw;
    width: 180%;
  }
  .containing-image1-div h2 {
    margin: 0 8%;
  }
} */

@media screen and (max-width: 440px) {
  .imageCard-main {
    overflow-x: scroll;
    justify-content: space-between;
  }

  .containing-image1-div {
    justify-content: center;
    margin-right: 70vw;
    width: 150%;
  }
  .containing-image1-div h2 {
    margin: 0 8%;
  }
}

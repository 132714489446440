/* wee will use this color for sundarjag website ..#f8a91a,
#12384b,
#c4dcfd,
#5180ad */

.poster-container {
  background-color: #ffdee9;
  background-image: linear-gradient(
    0deg,
    #fff 0%,
    #fae9efc8 20%,
    #c9fdfbb4 100%
  );

  height: 90vh;
  display: flex;
  align-items: center;
  /* overflow: hidden;
    background: linear-gradient(135deg, rgba(18, 17, 18, 0.527), rgba(123, 97, 10, 0.628)),
                url("./traveling-poster-3.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed; */
}

.content {
  height: 90%;
  width: 95%;
  margin: 5px auto;
  object-fit: cover;
  border-radius: 20px;
  /* position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff; */
}

@media screen and (max-width: 768px) {
  .Heading {
    width: 400px;
    padding: 2px 20px;
    font-size: 30px;
  }
  .tag-line {
    width: 400px;
    padding: 10px 20px;
    margin-top: 50px;
  }
  .poster-container {
    background-position: right;
  }
}
@media screen and (max-width: 480px) {
  .poster-container {
    height: 78vh;
  }
}

/* ContactUs.css */
.main-container {
  max-width: 100%;
  height: 650px;
  background: whitesmoke;
  position: relative;
  padding-top: 8rem;
}

.strip {
  background-color: #12384b; /* Choose your desired background color */
  height: 200px; /* Adjust the height as needed */
  width: 100%; /* Set the width to 100% to span the full width of the container */
  margin: 90px 0; /* Add margin for spacing, adjust as needed */
  position: absolute;
  z-index: auto;
}

.contact-us-container {
  position: absolute;
  flex-wrap: wrap;
  background: #fff;
  justify-content: space-between;
  align-items: center; /* Vertically center the content */
  max-width: 1000px; /* Adjust the max-width as needed */
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  margin: 0 14vw;
  z-index: 1;
  box-shadow: 0 0 10px 5px #12384b6b;
  transition: background-color 0.3s ease;
}
.contact-details-container h2 {
  padding-left: 0px;
  padding-bottom: 4px;
  font-weight: 500;
  font-size: 24px;
  text-align: left;
}
.contact-us-container .form-container {
  flex: 1;
  border-radius: 8px;
  padding-top: 20px;
  margin-bottom: 50px;
}
.contact-page textarea,
.form-group-two input {
  width: 400px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-left: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  transition: background-color 0.3s ease;
}
/* input {
  transition: background-color 0.3s ease;
} */
.contact-page button {
  padding: 12px 80px 12px 80px;
  background-color: #12384b;
  color: white;
  border: none;
  margin-left: 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-page button:hover {
  background-color: #f8a91a;
  font-weight: 700;
  color: #000;
}
.social-media-list li {
  position: relative;
  display: inline-block;
  height: 30px;
  width: 60px;
}

.social-media-list li:after {
  content: "";
  position: absolute;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  opacity: 0;
  box-shadow: 0 0 0 1px #12384b;
  transition: all 0.2s ease-in-out;
}

.social-media-list li:hover a {
  color: #f8a91a;
}
.social-media-list {
  position: relative;
  font-size: 22px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.social-media-list li a {
  color: #12384b;
}
/* Your existing CSS */

.contact-us-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0;
}

.contact-details-container {
  flex: 0 0 50%;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  margin: 0 20px 80px;
  box-sizing: border-box;
  transition: 0.3s ease; /* Add a transition for the box-shadow property */
}

@media (max-width: 768px) {
  .main-container {
    max-width: 100%;
    height: 880px;
    background: whitesmoke;
    position: relative;
    padding-top: 6rem;
  }
  .contact-us-container {
    position: absolute;
    flex-wrap: wrap;
    flex-direction: column;
    background: #fff;
    align-items: center;
    width: 65%;
    margin: 0 8rem;
    box-sizing: border-box;
    z-index: 1;
    box-shadow: 0 0 10px 5px #12384b6b;
    transition: background-color 0.3s ease;
  }
  .strip {
    height: 160px;
    margin-top: 17rem;
  }
  .contact-details-container {
    flex: 0 0 50%;
    width: 80%;
    background-color: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin: 0 0.5rem 1rem;
    box-sizing: border-box;
    transition: 0.3s ease; /* Add a transition for the box-shadow property */
  }
}

@media (max-width: 480px) {
  .main-container {
    max-width: 100%;
    height: 880px;
    background: whitesmoke;
    position: relative;
    padding-top: 6rem;
  }
  .contact-us-container {
    position: absolute;
    flex-wrap: wrap;
    flex-direction: column;
    background: #fff;
    align-items: center;
    width: 92.5%;
    margin: 0 1rem;
    box-sizing: border-box;
    z-index: 1;
    box-shadow: 0 0 10px 5px #12384b6b;
    transition: background-color 0.3s ease;
    padding: 0.5rem;
  }
  .strip {
    height: 160px;
    margin-top: 17rem;
  }
  .contact-details-container {
    flex: 0 0 50%;
    width: 88%;
    background-color: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin: 0 0 0.5rem;
    padding: 0.5rem 1rem 1rem;
    box-sizing: border-box;
    transition: 0.3s ease;
  }
  .contact-form {
    width: 80%;
  }
  .contact-form .form-group-two {
    width: 100%;
    padding: 1px;
  }
  .contact-page textarea,
  .form-group-two input {
    width: 80vw;
    padding: 0.2rem 0.7rem 0.2rem 0.2rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-left: 0.8rem;
    margin-bottom: 0.8rem;
    box-sizing: border-box;
    transition: background-color 0.3s ease;
  }
}

/* Basic styles for accordion */
.accordion-section {
    margin-bottom: 10px; /* Add some spacing between sections */
  }
  
  .accordion-title {
    cursor: pointer;
  }
  
  .accordion-content {
    
    padding: 10px; /* Add padding to content */
  }
  
  /* Responsive styles */
  @media screen and (max-width: 1100px) {
    .accordion-title {
        font-size: 14px; /* Further reduce font size for even smaller screens */
    }
    
    .accordion-content {
        padding: 8px; 
    }
    .accordion-section {
      margin-bottom: 10px; 
      width: 400px;
    }
}
  @media screen and (max-width: 768px) {
    .accordion-title {
      font-size: 16px; /* Reduce font size for smaller screens */
    }
     .accordion-content {
        font-size: 12px;
        
      }
      .accordion-section {
        margin-bottom: 10px; 
        width: 350px;
      }
    }
    
    @media screen and (max-width: 480px) {
        .accordion-title {
            font-size: 14px; /* Further reduce font size for even smaller screens */
        }
        
        .accordion-content {
            padding: 8px; 
        }
        .accordion-section {
          margin-bottom: 10px; 
          width: 250px;
        }
    }
   
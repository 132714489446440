.imageh1 {
  color: #2563eb;
  text-align: center;
}
.imageGallery {
  margin: 20px 0;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-width: 28%;
  column-width: 28%;
  padding: 0 15px;
}
.imageGallery .pics {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 12px;
}

.imageGallery .pics img {
  border-radius: 20px;
}
.imageGallery .pics img:hover {
  border-radius: 20px;
}
.imageGallery .pics:hover {
  filter: opacity(0.8);
  transform: scale(0.9);
}

@media (max-width: 768px) {
  .imageGallery {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}
@media (max-width: 480px) {
  .imageGallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    column-width: 100%;
  }
}

/*css for model*/
.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 5px;
  background: #000;
  z-index: 999;
}

.model-close-btn {
  position: fixed;
  top: 5vw;
  right: 5vw;
  padding: 3px 10px;
  background-color: #000;
  color: #fff;
  font-size: 2rem;
  cursor: pointer;
  z-index: 1001;
}
.model button:hover {
  padding: 3px 20px;
}
.model img {
  width: 50%;
  height: 50%;
  line-height: 0;
  display: block;
  box-sizing: border-box;
  padding: 1.5rem 0 1.5rem;
  margin: 2rem auto;
}

.home-container{
    background-image: url("./traveling-poster-2.jpg");
    background-size: cover;
}

@media screen and (max-width: 600px) {
    .home-container{
        background-position: left;
        
    }
}


@media screen and (max-width: 440px){
    .TravelingAddPoster{
        display: none;
    }
}

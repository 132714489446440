.input-box-container {
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: 40px auto;
  margin-top: 6rem;
  background-color: #fff;
  box-shadow: 1px 1px 10px #dadada;
  padding: 10px 5px;
  position: relative;
}
.signup-title {
  font-weight: 100;
  font-size: 40px;
  text-align: center;
}
.input-box-container .title {
  font-weight: 100;
  font-size: 40px;
  color: #000;
  text-align: center;
}

.input-box {
  margin: 10px 30px;
  padding: 5px 0px;
  font-size: 20px;
  outline: none;
  border: none;
  border-bottom: 1px solid #b2b2b2;
}

.signup-btn {
  padding: 10px 25px;
  margin: 20px auto;
  margin-top: 1rem;
  /* width: 150px; */
  width: 12rem;
  font-size: 16px;
  color: #fff;
  background-color: #12384b;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.signup-link {
  padding-top: 20px;
}

.signup-btn:hover {
  background-color: #f8a91a;
  color: #000;
  font-weight: 600;
  font-size: 16px;
}

.link {
  color: blue;
  cursor: pointer;
  text-decoration: none;
}

.link-position {
  text-align: center;
  font-size: 1.2rem;
}

@media only screen and (max-width: 600px) {
  .input-box-container {
    width: 80vw;
  }

  .input-box {
    font-size: 15px;
  }

  .signup-btn {
    width: 40vw;
  }
}

.contact-contentOnHome {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  align-items: center;
}

.contact-contentOnHome .leftcontentOnHome {
  width: 50%;
}

.contact-contentOnHome .leftcontentOnHome h2 {
  font-size: 28px;
  font-weight: 600;
  padding: 10px 30px;
}

.contact-contentOnHome .leftcontentOnHome p {
  padding: 10px 30px;
  text-align: left;
  font-size: 16px;
  font-weight: 300;
}

.contact-contentOnHome .rightcontentOnHome {
  width: 50%;
}

.contact-contentOnHome .rightcontentOnHome img {
  padding: 20px 20px;
  margin: 0 auto;
  width: 400px;
  height: 450px;
}

.contact-buttonOnHome {
  width: 30%;
  margin: 0 32px;
}

.contact-buttonOnHome button {
  padding: 10px;
  width: 100%;
  background-color: #12384b;
  text-align: center;
  color: #fff;
  border-radius: 4px;
}

.contact-buttonOnHome button:hover {
  background-color: #f8a91a;
  font-weight: 700;
  color: #000;
}

.contact-contentOnHome .rightcontentOnHome h2 {
  display: none;
}

@media (max-width: 430px) {
  .contact-contentOnHome .leftcontentOnHome {
    width: 100%;
  }

  .contact-contentOnHome .rightcontentOnHome {
    width: 100%;
  }

  .contact-contentOnHome .rightcontentOnHome img {
    padding: 20px 20px;
    margin: 0 auto;
    /* width: 400px; */
    height: 350px;
  }

  .contact-buttonOnHome {
    width: 50%;
    margin: 10px 0;
  }

  .contact-contentOnHome .leftcontentOnHome p {
    padding: 10px 0;
    text-align: left;
    text-align: justify;
  }
  .contact-contentOnHome .leftcontentOnHome h2 {
    /* padding: 10px 0;
    text-align: left;
    text-align: justify; */
    display: none;
  }
  .contact-contentOnHome .rightcontentOnHome h2 {
    padding: 0;
    position: relative;
    top: 30px;
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    display: block;
  }
  .contact-contentOnHome {
    flex-direction: column-reverse;
  }
}

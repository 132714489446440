/* Footer.css */

.footer-container {
  margin-top: 50px;
  background-color: #12384b;
  color: #ffffff;
  padding: 20px 0;
  text-align: center;
}
.footer-section { 
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.footer-content {
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-left: 50px;
}
.footer-logo img {
  padding: 5px;
  padding-right: 0px;
  border-radius: 2px;
  background-color: #ffffff;
  position: relative;
}

.logo-img1{
  display: none;
}
.logo-img2{
  height: 210px; 
  position: absolute;
  top: 20px;
  
  margin: 0;
}


.footer-main-links {
  padding-right: 0px;
}
.torrist-service {
  padding-right: 0px;
}
.contact-info {
  margin-right: 0px;
}
.contact-info p {
  padding-top: 2px;
  width: 100%;
}
.contact-info h2 {
  margin: 0 1rem 0 0;
  padding: 0.6rem 1rem 0.5rem 1rem;
}
.contact-info-email {
  padding-bottom: 8px;
}

.copyright-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  padding-bottom: 10px;
}
.copy-heading {
  padding-left: 10px;
}
.copy-content {
  width: 60%;
  text-align: center;
  margin: 0 auto;
}
.follow-us {
  padding-right: 20px;
}
.footer-section {
  flex: 1;
}

.footer-section h2 {
  font-size: 1.5rem;
  color: rgb(223, 154, 7);
  text-align: center;
}
.footer-content ul {
  list-style: none;
  padding: 0;
}

.footer-section h2 {
  font-size: 1.2rem;
  font-weight: bold;
  color: rgb(223, 154, 7);
}
.footer-content ul {
  list-style: none;
  padding: 0;
}

.footer-content ul li {
  margin-bottom: 10px;
}

.footer-content a {
  text-decoration: none;
  color: #ffffff;
}

.social-icons {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.social-icons a {
  color: #ffffff;
  font-size: 1.5em;
  margin: 0 10px;
}

@media (max-width: 440px) {
  .footer-main-links {
    padding: 0;
    margin: 2px auto;
  }

  .logo-img1{
    display: block;
    height: 60px;
  }
  .logo-img2{
    display: none;
  }
  .torrist-service {
    padding: 0;
    margin: 2px auto;
  }
  .contact-info {
    padding: 0;
    margin: 2px auto;
  }
  .follow-us {
    padding: 0;
    margin: 2px auto;
  }
  /* .footer-logo img {
    position: absolute;
    z-index: -100;
    top: 20px;
  } */

  .copy-content {
    width: 100%;
  }
  .contact-info {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 0;
  }
  .footer-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .footer-logo {
    padding: 2px;
    margin-top: 10px;
  }
  .copyright-footer {
    margin-top: 5rem;
    padding-bottom: 2px;
  }
}

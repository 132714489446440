/* Add this part to your existing CSS code */

.review-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(-20px);
  animation: fadeInUp 0.5s ease-out forwards;
}
.retme-span{
  position: relative;
  z-index: 500;
  left: 92vw;
  top: 40px;

}
.ratme{
  width: 40px;
  border-bottom:2px dashed rgb(147, 130, 130) ;
 position: relative;
 z-index: 40;
}

/* react model css */

.popup {
  height: 300px;
  width: 500px;
  border: 1px solid rgb(207, 205, 205);
  margin: 200px auto;
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  /* background-color: #21193534; */
}
.popup-heding {
  font-size: 25px;
  font-weight: bold;
}
.input-table {
  border: 1px solid rgb(191, 190, 190);
  padding: 5px 20px;
  border-radius: 5px;
  width: 460px;
}
.btn-create {
  background-color: #12384b;
  padding: 5px 8px;
  border-radius: 5px;
  color: white;
}
.input-table-width{
  width: 420px;
}
.review-scroll-container{
  height: 300px;
  width: 100%;
  /* padding: 10px 20px; */
  /* padding-left: 200px; */
  margin-top: 40px;
  margin-left: 10px;
  margin-right: 10px;
  overflow-x: scroll !important;
  display: flex;
  /* justify-content: center;  */
  /* border: 1px  solid black; */
}
.review-scroll-container::-webkit-scrollbar {
  /* display: none; */
  display: none;
}
.div-container{ 
  display: flex;
  justify-content: center; 
}

.scroll{
  display: flex;
  overflow-X: scroll;
  padding:20px 100px ;
  justify-content: center; 
  /* margin: 0px; */
}



@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.filter-container {
  display: flex;
  justify-content: flex-start;
}

.filter-options {
  position: relative;
}

.filter-button {
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  background-color: transparent;
}

.filter-options-list {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
}

.filter-options-list button {
  display: block;
  padding: 12px;
  border: none;
  background-color: #fff;
  color: #12384b;
  text-align: left;
  border-radius: 450px;
  cursor: pointer;
  margin-right: 2px;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s;
}

.filter-options-list button:hover {
  background-color: #f8a91a;
}

.filter-options-list .filter-button {
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  cursor: default;
}

.filter-options-list .filter-button:hover {
  background-color: transparent;
}

.reviews {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  
}

.review {
  flex: 0 0 calc(25% - 20px);
  margin: 10px;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  overflow: hidden;
  word-wrap: break-word;
  transition: box-shadow 0.3s, transform 0.3s;
  opacity: 0;
  transform: translateY(-20px);
  animation: fadeInUp 0.5s ease-out forwards, fadeInCards 0.5s ease-out forwards;
}

@keyframes fadeInCards {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.review:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  transform: scale(1.02);
}

.review p {
  margin: 0;
}

.review-meta {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.review-meta a {
  color: #12384b;
  cursor: pointer;
  margin: 0 auto;
  margin-right: 2px;
  transition: 0.3s;
  transform: translateY(-20px);
  animation: fadeInUp 0.5s ease-out forwards;
}
.review-date {
  position: absolute;
  /* top: 90px; */
}
.add-review {
  margin-top: 20px;
  opacity: 0;
  transform: translateY(-20px);
  animation: fadeInUp 0.5s ease-out forwards;
}

.add-review1 {
  float: right;
}

textarea {
  width: 100%;
  padding: 12px;
  margin-bottom: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: box-shadow 0.3s;
  opacity: 0;
  transform: translateY(-20px);
  animation: fadeInUp 0.5s ease-out forwards;
}

textarea:focus {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
textarea:hover {
  border-color: #12384b; /* Change border color on hover */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add shadow on hover */
}
button .cancle {
  background-color: #12384b;
  color: #fff;
  padding: 12px;
  margin-left: 10px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}
.add-review1 button img {
  display: inline-flex;
  margin-right: 10px;
}

.add-review1 button,
.add-review button {
  background-color: #12384b;
  color: #fff;
  padding: 12px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 2px;
  transition: background-color 0.3s;
  opacity: 0;
  transform: translateY(-20px);
  animation: fadeInUp 0.5s ease-out forwards;
}

.add-review1 button:hover,
.add-review button:hover {
  background-color: #f8a91a;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  opacity: 0.9;
  color: #000;
  /* animation: 0.9s ease-in infinite; shake */
}
/* @keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  25%,
  75% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
} */

@media (max-width : 430px){
  .retme-span{
    left: 70vw;
    top : -20px
  }
  .popup {
    height: 300px;
    width: 330px;
    border: 1px solid rgb(207, 205, 205);
    margin: 200px auto;
    padding: 20px;
    border-radius: 10px;
    background-color: white;
    /* background-color: #21193534; */
  }
  .input-table {
    border: 1px solid rgb(191, 190, 190);
    padding: 5px 20px;
    border-radius: 5px;
    width: 290px;
  }
  .input-table-width{
    width: 250px;
  }
}
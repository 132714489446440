.videoh1 {
  color: #2563eb;
  text-align: center;
}
.videoGallery {
  margin: 0.8rem 0;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-width: 33%;
  column-width: 33%;
  padding: 0.8rem;
}
.vid .video-container {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  margin-bottom: 12px;
}

.video-container {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
@media (max-width: 768px) {
  .videoGallery {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-width: 45%;
    column-width: 45%;
  }
}
@media (max-width: 480px) {
  .videoGallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    column-width: 100%;
  }
}
.play-button {
  position: absolute;
  bottom: 40%;
  left: 40%;
  color: #fff;
  background: linear-gradient(
    170deg,
    rgba(165, 55, 237, 1) 0%,
    rgba(58, 124, 242, 1) 0%,
    rgba(176, 47, 255, 1) 100%
  );
  padding: 1.5rem 1.8rem;
  border-radius: 100%;
}
.play-button .pb-text {
  padding-top: 0.2rem;
  font-size: 24px;
}
.play-button:hover {
  background: #fff;
  color: color-mix(in srgb-linear, #b02fff 50%, #3a7cf2 60%);
  box-shadow: 1px 1px 10px 3px
    color-mix(in srgb-linear, rgba(173, 47, 251, 0.614) 50%, #3a7ef2be 60%);
}
.container-h3 {
  position: absolute;
  color: #fff;
  bottom: 1%;
  left: 5%;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  font-weight: 600;
}
.container-p {
  position: absolute;
  bottom: 1%;
  color: #dadadd;
  left: 5%;
  font-size: 16px;
  margin-bottom: 0.8rem;
}
/*css for model*/
.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: #000;
  z-index: 999;
}

.model .model-close-btn {
  position: fixed;
  top: 5vw;
  right: 5vw;
  padding: 3px 10px;
  background-color: #000;
  color: #fff;
  font-size: 2rem;
  cursor: pointer;
  z-index: 1001;
}
.model button:hover {
  padding: 3px 10px;
  background-color: #f8a91a;
  border-radius: 5px;
}
.model .video-container {
  width: 100%;
  height: 100%;
  line-height: 0;
  display: block;
  box-sizing: border-box;
  padding: 1.5rem 0 1.5rem;
  margin: 2rem auto;
}
.container-wala-video {
  width: fit-content;
  height: 100%;
  margin: 0 auto;
  border-radius: 5px;
}

/* Basic styles for carousel */
.caurousal-img {
    width: 100%; /* Make images and videos responsive */
    height: auto;
  }
  
  /* Responsive styles */
  @media screen and (max-width: 768px) {
    .slick-slider {
      width: 60%; /* Reduce width of slider for smaller screens */
     margin-left: 5px;
     margin-bottom: 0px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .slick-slider {
      width: 40%; /* Further reduce width of slider for even smaller screens */
    }
  }
  
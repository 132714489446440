.review-card{
    width: 400px;
    height: 200px;
    margin: 20px 20px;
    padding: 10px 10px;
    border: 1px solid rgb(218, 212, 212);
    border-radius: 5px;
    position: relative ;
    cursor: pointer;
}
.review-card:hover{
    box-shadow: 0 2px 20px 0 rgb(207, 206, 206);
    /* transition: 1s; */
}
.review-card p {
    height: 100px;
    overflow-x: hidden;
    margin-top: 20px;
    
    text-align: justify;
    padding: 2px 10px;
}

.review-card p::-webkit-scrollbar {
    /* display: none; */
    display: none;
  }
.rating{
    position: absolute;
    top: 10px;
    right: 10px;
}
.profile-img{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: inline-block;
  }
  .col-light{
    color: rgb(96, 95, 95);
  }


  @media (max-width : 440px) {
    .review-card{
      width: 380px ; 
      margin-left:0 ;
    }
  }
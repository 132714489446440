* {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}
.inside-this {
  width: 100%;
}
.serviceh1{
  position: absolute;
  top: 60px;
  width: 100%;
}
.headingservice {
  font-size: 4rem;
  text-shadow: 2px 4px 5px  #b5c0d9;
  font-weight: bold;
  text-align: center;
}

.main {
  position: relative;
}
.top img {
  width: 100%;
  height: 550px;
}
.imagediv {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
/* #c4dcfd #5180ad */
.service-item {
  margin: 2rem auto;
  flex: 0 0 calc(50% - 4vw);
  justify-content: space-between;
}

.image-overlay {
  position: relative;
  overflow: hidden;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.483);
  border-radius: 10px;
}
.image-overlay img {
  width: 1000px;
  height: 400px;
}
/* .image-overlay img:hover {
  filter: brightness(50%);
} */
.image-overlay img:hover {
  opacity: 0.9;
  background: linear-gradient(#dadada, #000);
}
.overlay-text {
  width: 80%;
  height: 60px;
  color: #fff;
  padding: 5px 5px;
  left: 10px;
  bottom: 10px;
  text-shadow: 2px 2px #00000078;
  font-size: 40px;
  font-weight: 900;
  position: absolute;
}
.overlay-content:hover {
  background: rgba(0, 0, 0, 0.575);
  backdrop-filter: saturate(180%) blur(10px);
}
.overlay-content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  top: 50%;
  left: 50%;
  padding: 0 1.8rem;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 1.3rem;
  text-align: justify;
  font-weight: 600;
}

.overlay-content.visible {
  opacity: 1;
}

.why-us {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;
  padding: 4rem 0;
  background: whitesmoke;
}
.why-choose-us {
  margin-right: 30px;
  margin-left: 38px;
  width: 50%;
}
.choose-h3 {
  margin-bottom: 20px;
  font-size: 32px;
  padding: 0 1rem 0.2rem;
}
.choose-p {
  width: 80%;
  padding: 0 1rem 0.6rem;
  font-size: 18px;
  font-weight: 500;
}
.choose-li p {
  padding: 0 1rem 0.6rem;
  font-size: 18px;
  font-weight: 200;
}
.choose-span {
  color: green;
  font-size: 20px;
  padding-right: 10px;
}
.what-you-get {
  width: 50%;
  margin-left: 10px;
}
.get-h3 {
  padding: 0.8rem 1rem 0;
  margin-bottom: 20px;
  font-size: 32px;
}
.get-p {
  width: 80%;
  padding: 0 1rem 0.8rem;
  font-size: 18px;
  font-weight: 500;
}
.get-li p {
  padding: 0 1rem 0.8rem;
  font-size: 18px;
  font-weight: 200;
}
.get-span {
  color: green;
  font-size: 20px;
  padding-right: 10px;
}

@media screen and (max-width: 769px) {
  .imagediv {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .image-overlay {
    width: 102%;
    height: 400px;
  }

  .overlay-text {
    width: 100%;
    height: 60px;
    color: #fff;
    padding: 0.1rem 0.5rem 0;
    left: 0;
    bottom: 0;
    text-shadow: 2px 2px #00000078;
    font-weight: 900;
    position: absolute;
    display: flex;
    text-align: center;
  }
  .overlay-text h3 {
    text-align: left;
    font-size: 18px;
  }
  .overlay-content {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    top: 50%;
    left: 50%;
    padding: 0 1rem;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: justify;
    font-size: 1.2rem;
    font-weight: 600;
  }
  .why-us {
    margin: 0 auto;
    padding: 1rem 0;
  }
  .why-choose-us {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: 0.9rem 0.5rem 0 0;
  }
  .why-choose-us h3 {
    width: 80%;
    text-align: left;
  }
  .why-choose-us .choose-li {
    margin: 2rem;
  }
  .why-choose-us .choose-li p {
    padding: 0.2rem 0;
    font-size: 18px;
  }
  .what-you-get {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
  .what-you-get h3 {
    width: 80%;
    text-align: left;
  }
  .what-you-get .get-li {
    margin: 2rem;
  }
  .what-you-get .get-li p {
    padding: 0.2rem 0;
  }
}

@media screen and (max-width: 480px) {
  .top img {
    width: 100%;
    height: 500px;
  }
  .imagediv {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    flex-direction: column;
  }

  .image-overlay {
    margin: 0 auto;
    width: 96%;
    object-fit: cover;
    height: 300px;
  }

  .overlay-text {
    width: 100%;
    height: 60px;
    color: #fff;
    padding: 0.1rem 0.5rem 0;
    left: 1rem;
    text-shadow: 2px 2px #00000078;
    font-weight: 900;
    position: absolute;
    display: flex;
    text-align: center;
  }
  .overlay-text h3 {
    text-align: left;
    font-size: 18px;
  }
  .overlay-content {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    top: 50%;
    left: 50%;
    padding: 0 1rem;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: justify;
    font-size: 1rem;
    font-weight: 600;
  }
  .overlay-content:focus {
    background: rgba(0, 0, 0, 0.575);
    backdrop-filter: saturate(180%) blur(10px);
  }
  .why-us {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;
    padding: 1rem 0;
  }
  .why-choose-us {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .why-choose-us h3 {
    width: 80%;
    text-align: left;
  }
  .why-choose-us .choose-li {
    margin: 2rem;
  }
  .why-choose-us .choose-li p {
    padding: 0.2rem 1rem;
  }
  .what-you-get {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .what-you-get h3 {
    width: 80%;
    text-align: left;
  }
  .what-you-get .get-li {
    margin: 2rem;
  }
  .what-you-get .get-li p {
    padding: 0.2rem 1rem;
  }
}

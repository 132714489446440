.home-img-gallery {

    max-width: 1250px;
    margin: 0 auto;
}

.gallery-header {
  text-align: center;
  margin-bottom: 20px;
}

.gallery-header h1 {
  margin-top: 20px;
  font-size: 32px;
  margin-bottom: 8px;
}

.gallery-header p {
  color: #666;
}

.gallery-quote {
  text-align: center;
  margin-bottom: 20px;
}

.gallery-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.gallery-item {

    flex: 0 0 calc(33.333% - 10px);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
   
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-item:hover img {
  transform: scale(1.1);
}

/* Responsive styles */
@media (max-width: 480px) {
  .gallery-item {
    flex: 0 0 calc(50% - 10px);
  }

  .gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
  }
}


@media (max-width: 440px) {
    .gallery-item {
        flex: 0 0 95%;
        margin: 5px auto;
        
    }

    .gallery-item img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        
    }
}

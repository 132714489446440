.nav-container {
  display: flex;
  justify-content: space-between;
  height: 90px;
  background-color: white;
  box-shadow: 0 2px 20px 0 rgb(120, 120, 120);
  margin: 0px;
  padding: 20px;
  font-size: 20px;
  align-items: center;
  position: relative;
}

.nav-btn {
  margin: 0 10px;
  padding: 5px 10px;
  font-size: 22px;
  text-decoration: none;
  color: black;
}

.nav-btn:hover {
  transition: 150ms;
  color: rgb(223, 154, 7);
}

.btn-logout {
  padding: 5px 20px;
  margin-left: 20px;

  background-color: #09284a;
  color: aliceblue;
  border-radius: 10px;
}

.user {
  display: inline-block;
  justify-content: space-around;
}


.logo-img {
  height: 60px;
}

.menu-btn {
  width: 25px;
  position: absolute;
  right: 30px;
  display: none;
}

@media screen and (max-width: 768px) {
  .menu-btn {
    display: block;
    top: 40px;
  }

  .nav-container {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: start;
  }

  .nav-btn {
    margin: 0 0px;
    padding: 15px 0px;
    display: flex;

    margin: 0 10px;
    padding: 5px 10px;
    font-size: 12px;

    text-decoration: none;
    color: black;
  }

  .nav-btn:hover {
    background-color: white;
    transition: 0s;
    border-radius: 5px;
  }

  .btn-logout {
    margin-left: 0px;
    margin-top: 20px;
    padding: 5px 20px;
    margin-left: 20px;
    background-color: #09284a;
    color: aliceblue;
  }
  .btn-logout:hover {
    border-radius: 15% 30%;
  }
  .user {
    display: inline-block;
    justify-content: space-around;
  }
  .logo-img {
    height: 80px;
  }

  .user {
    flex-direction: column;
  }

  .disply {
    display: none;
  }

  .links {
    display: block;
  }

}
  @media screen and (max-width: 768px) {
    .menu-btn {
      display: block;
      top: 40px;
    }
  
    .nav-container {
      display: flex;
      flex-direction: column;
      z-index: 9999;
      justify-content: center;
      align-items: start;
    }

    .nav-btn {
      margin: 0 auto;
      padding: 15px 0px;
      display: flex;
      font-size: 20px;
      justify-content: center;
      text-decoration: none;
      color: black;
    }
    .nav-btn:hover {
      background-color: white;
      transition: 0s;
      border-radius: 5px;
    }
    .btn-logout {
      font-size: 20px;
      margin-left: 0px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .user {
      flex-direction: column;
    }

    .links {
      position: relative;
      width: 100vw;
      padding: 0;
      top: 190px;
      right: 20px;
      background-color: #fff;
      text-align: center;
    }
    .logo {
      position: relative;
    }
    .disply {
      display: none;
    }
  }

@media screen and (max-width: 480px) {
  .nav-container {
    z-index: 9999;
  }
  .logo-img {
    height: 60px;
  }
}

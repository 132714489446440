/* .contact-head {
    width: 90%;
    height: 100%;
    margin: 0 auto;
  }

  .contact-content {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 90%;
    left: 10%;
    margin: 5% 20px;
    align-items: center;
    margin-bottom: 13%;
  } */
.contact-new-container {
  background-image: url("contactimg.jpg");
  width: 98.9vw;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: start;
  align-items: last baseline;
  /* margin-bottom: 20px; */
}
.contact-imgdiv img {
  width: 100%;
  height: 70vh;
  z-index: 0;
}
/* .contact-heading h1 {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  color: #12384b;
  text-shadow: 2px 2px 2px #000;
  font-size: 60px;
  font-weight: 800;
  z-index: 9;
} */
.contact-new-container .contact-data {
  height: 100vh;
  /* backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.253); */
}
.leftcontent {
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.304);
  border-radius: 5px;
}
.contact-data {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-new-container .leftcontent {
  width: 50%;
  /* color: #fff; */
  color: #000;
  padding-bottom: 25px;
  /* margin-bottom: 20vh; */
}
.leftcontent p,
h2 {
  padding: 10px 40px;
  text-align: left;
}
.leftcontent h2 {
  text-transform: capitalize;
  margin: 0 auto;
  font-size: 64px;
  font-weight: 600;
}
.leftcontent p {
  font-size: 20px;
  font-weight: 300;
}
/* .contact-content .rightcontent {
  width: 50%;
}
.contact-content .rightcontent img {
  padding: 20px 20px;
  margin: 0 auto;
  width: 400px;
  height: 400px;
} */

.contact-button {
  width: 30%;
  margin: 0 32px;
  margin-top: 20px;
  margin-left: 40px;
}
.contact-button button {
  padding: 10px;
  width: 100%;
  background-color: #12384b;
  text-align: center;
  color: #fff;
  border-radius: 4px;
}
.contact-button button:hover {
  background-color: #f8a91a;
  font-weight: 700;
  color: #000;
}

@media screen and (max-width: 480px) {
  .contact-new-container {
    background-image: url("contactimg.jpg");
    width: 100%;
    background-size: cover;
    height: 80vh;
    display: flex;
    align-items: center;
  }
  .contact-new-container .leftcontent {
    width: 90%;
    height: 45%;
  }
  .leftcontent h2 {
    text-transform: capitalize;
    font-size: 24px;
    margin: 0;
    padding: 0.2rem 1rem 0.2rem;
    text-align: left;
  }
  .leftcontent p {
    font-size: 16px;
    padding: 0 1rem;
    text-align: left;
  }
  .contact-new-container .contact-data {
    height: 125vh;
  }
  .contact-button {
    width: 100%;
    margin: 0.5rem 1rem;
  }
  .contact-button button {
    width: 200%;
  }
}

@media screen and (max-width: 768px) {
  .contact-new-container {
    background-image: url("contactimg.jpg");
    width: 100%;
    background-size: cover;
    height: 120vh;
    display: flex;
    align-items: center;
  }
  .contact-new-container .leftcontent {
    width: 80%;
  }
  .leftcontent h2 {
    text-transform: capitalize;
    margin: 0 auto;
    font-size: 32px;
    padding: 0.2rem 1rem;
    font-weight: 600;
    text-align: left;
  }
  .leftcontent p {
    font-size: 16px;
    padding: 0 1rem;
    text-align: left;
  }
  .contact-button {
    width: 30%;
    margin: 0.5rem 1rem 0;
  }
  .contact-new-container .leftcontent {
    padding-bottom: 0.5rem;
  }
}

/* TravelAdPoster.css */

.travel-ad-poster-container {
    padding: 50px;
    background-color: #c4dcfd;
    /* Adjust the background color as needed */
}

.travel-ad-poster-content {
    text-align: center;
    color: #12384b;
    /* Adjust the text color as needed */
}

h1 {
    font-size: 3em;
    margin-bottom: 20px;
}

.slogan {
    font-size: 1.5em;
    margin-bottom: 30px;
}

.travel-ad-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    /* Add rounded corners to the image */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* Add a subtle box shadow */
}
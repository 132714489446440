.package-maindiv {
  margin: 0 auto;
  margin-top: 20px;
  align-items: center;
}
.package-card {
  position: relative;
  width: 350px;
  margin: 0 5px;
}
.package-containig-cards {
  display: flex;
  justify-content: space-between;
  margin: 1rem 1rem;
}
.package-card-title {
  position: absolute;
  color: #fff;
  font-weight: 300;
  font-size: 22px;
  text-shadow: 2px 2px 2px #00000076;
  bottom: 28px;
  margin-bottom: 6%;
}
.package-card button {
  position: absolute;
  margin-left: 40px;
  color: #fff;
  background: #12384b;
  font-weight: 300;
  font-size: 24px;
  width: 140px;
  padding: 3px 8px;
  bottom: 10%;
  border-radius: 9px;
  /* transition: opacity 0.3s ease-in-out; */
  opacity: 0;
}

.package-card button:hover {
  position: absolute;
  color: #000;
  background-color: #f8a91a;
  font-weight: 300;
  font-size: 24px;
  bottom: 10%;
  z-index: 1;
}
.package-card-content {
  width: 80%;
  position: absolute;
  font-size: 16px;
  color: #fff;
  text-shadow: 2px 2px #00000056;
  bottom: 8px;
  margin-left: 12%;
  margin-bottom: 2%;
  text-wrap: wrap;
}

.package-card img {
  object-fit: cover;
  border-radius: 20px;
  width: 320px;
  height: 300px;
  cursor: pointer;
  margin: 2% auto;
  z-index: 1;
}

.package-card img:hover {
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.466);
  /* background: rgba(0, 0, 0, 0.973);
  backdrop-filter: saturate(180%) blur(10px); */
}
.package-card:hover .package-button {
  opacity: 1;
}
.package-card:hover .package-card-title {
  margin-bottom: 26%;
}
.package-card:hover .package-card-content {
  margin-bottom: 23%;
}

@media screen and (max-width: 768px) {
  .package-containig-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 1rem auto;
  }
  .package-card {
    margin: 10px 0;
  }
  /* .package-card-content {
    width: 90%;
    position: absolute;
    font-size: 16px;
    color: #fff;
    text-shadow: 2px 2px #00000056;
    bottom: 8px;
    margin-bottom: 2%;
    text-wrap: wrap;
  } */
  .package-card-title {
    position: absolute;
    color: #fff;
    font-weight: 300;
    font-size: 24px;
    text-shadow: 2px 2px 2px #00000076;
    bottom: 5vh;
    margin-bottom: 13%;
    padding: 2px 24px;
  }
  .package-card-content {
    width: 90%;
    position: absolute;
    font-size: 16px;
    color: #fff;
    text-shadow: 2px 2px #00000056;
    bottom: 8px;
    margin-left: 8%;
    margin-bottom: 2%;
    text-wrap: wrap;
  }
  .package-card button {
    margin: 5px 24px 0;
  }
}

@media screen and (max-width: 440px) {
  .package-containig-cards {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1rem auto;
  }
  .package-card {
    margin: 0 auto;
  }
  .package-card:hover .package-card-title {
    margin-bottom: 27%;
  }
  .package-card-content {
    width: 90%;
    position: absolute;
    font-size: 16px;
    color: #fff;
    text-shadow: 2px 2px #00000056;
    bottom: 8px;
    margin-left: 5%;
    margin-bottom: 2.2%;
    text-wrap: wrap;
  }
  .package-card-title {
    position: absolute;
    color: #fff;
    font-weight: 300;
    font-size: 24px;
    margin-left: 0.2rem;
    text-shadow: 2px 2px 2px #00000076;
    bottom: 28px;
    margin-bottom: 5.5%;
    padding: 2px 24px;
  }
  .package-card-content {
    width: 90%;
    position: absolute;
    font-size: 16px;
    color: #fff;
    text-shadow: 2px 2px #00000056;
    bottom: 0px;
    margin-left: 8%;
    margin-bottom: 2.5%;
    text-wrap: wrap;
  }
  .package-card button {
    margin: 5px 29px 0;
  }
  .package-card img {
    width: 480px;
  }
  .package-maindiv {
    width: 100%;
  }
}
